import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        scrollToPosition: {type: Number, default: 150} // Set default value to 150
    }

    connect() {
        setTimeout(() => {
            const element = document.querySelector(`#row${this.closestEventDate()}`);
            if (element) {
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.scrollY - this.scrollToPositionValue;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        }, 100); // Adjust delay as needed
    }

    closestEventDate() {
        return this.data.get('closestEvent');
    }
}
