import {Controller} from "@hotwired/stimulus";
import {post} from "@rails/request.js"; // Using Rails UJS for Turbo-compatible requests

export default class extends Controller {
    connect() {
        // Get all accordion collapse elements
        const accordionElements = this.element.querySelectorAll(".collapse");

        accordionElements.forEach((collapseElement) => {
            const accordionId = this.element.id;

            // Add event listener for when accordion section is shown (opened)
            collapseElement.addEventListener("show.bs.collapse", () => {
                this.updateAccordionState(accordionId, collapseElement.id, "open");
            });

            // Add event listener for when accordion section is hidden (closed)
            collapseElement.addEventListener("hide.bs.collapse", () => {
                this.updateAccordionState(accordionId, collapseElement.id, "closed");
            });
        });
    }

    async updateAccordionState(accordionId, collapseId, state) {
        const form = this.element.closest('form');
        const formData = new FormData(form);
        const params = new URLSearchParams();
        const toFrame = form.getAttribute('data-to-frame');

        // Read the existing URL query parameters (to get active filters)
        const currentParams = new URLSearchParams(window.location.search);

        // Append non-empty query parameters from the URL
        currentParams.forEach((value, key) => {
            if (value) { // Only include non-empty values
                params.append(key, value);
            }
        });

        // Append form data (non-empty values) to `params`
        for (const [key, value] of formData.entries()) {
            if (value) { // Only include non-empty form fields
                params.append(key, value);
            }
        }

        // Debugging output to see final params before sending
        console.log("Final params:", params.toString());

        // Make a POST request to your backend to update the session with the new state
        const response = await post("/accordion_state_managers.turbo_stream", {
            body: JSON.stringify({
                accordion_id: `${collapseId}`,
                state: state,
                to_frame: `${toFrame}`,
                q: `${params.toString()}` // Send final params
            }),
            contentType: "application/json",
        });

        if (response.ok) {
            console.log(`Accordion ${collapseId} is now ${state}`);
        } else {
            console.error("Failed to update accordion state on server");
        }
    }
}
