import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["pagination"];

    connect() {
        this.updateURL();
    }

    updateURL() {
        const links = this.paginationTarget.querySelectorAll("a");

        links.forEach(link => {
            link.addEventListener("click", (event) => {
                event.preventDefault();

                const url = new URL(link.href);
                const params = new URLSearchParams(window.location.search);

                // Preserve existing params (like filters from ransack)
                url.searchParams.forEach((value, key) => {
                    // Handle array-like parameters correctly
                    if (key.endsWith("[]")) {
                        params.append(key, value); // Append instead of setting for array-like params
                    } else {
                        params.set(key, value); // Use set for other params
                    }
                });

                // Set the new page param
                const page = url.searchParams.get("page");
                if (page === "1") {
                    params.delete("page");
                } else {
                    params.set("page", page);
                }

                // Construct the new URL
                const newUrl = `${window.location.pathname}${params.toString() ? `?${params.toString()}` : ''}`;

                // Update the browser's URL without reloading
                history.pushState({}, "", newUrl);

                // Use Turbo to load the new content
                Turbo.visit(newUrl);
            });
        });
    }
}
