import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.updateStatus();
        window.addEventListener('online', this.updateStatus);
        window.addEventListener('offline', this.updateStatus);
    }

    updateStatus = () => {
        const status = navigator.onLine;

        if (status) {
            this.element.classList.add('visually-hidden');
        } else {
            this.element.classList.remove('visually-hidden');
        }
    }
}
