import * as bootstrap from "bootstrap";

// Function to initialize popovers
function initPopovers() {
    document.querySelectorAll('[data-bs-toggle="popover"]').forEach(popover => {
        if (!popover.hasAttribute('data-bs-initialized')) {
            new bootstrap.Popover(popover);
            popover.setAttribute('data-bs-initialized', 'true'); // Prevent double initialization
        }
    });
}

// Initialize popovers on turbo:load
document.addEventListener("turbo:load", function () {
    initPopovers();
});

// Reinitialize popovers when a turbo-frame or turbo-stream is loaded
document.addEventListener("turbo:frame-load", function () {
    setTimeout(initPopovers, 150); // Allow DOM to render first
});

document.addEventListener("turbo:render", function () {
    setTimeout(initPopovers, 150); // Allow DOM to render first
});

document.addEventListener("turbo:frame-render", function () {
    setTimeout(initPopovers, 150); // Allow DOM to render first
});

document.addEventListener("turbo:before-stream-render", function () {
    setTimeout(initPopovers, 150); // Allow DOM to render first
});

// Handle popovers inside collapses
document.addEventListener("show.bs.collapse", function () {
    setTimeout(initPopovers, 150); // Initialize popovers when collapse content is visible
});

document.addEventListener("hide.bs.collapse", function () {
    setTimeout(initPopovers, 150); // Reinitialize when the collapse is hidden
});
